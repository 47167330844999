import "./style.css";

import { Container, MDBFooter } from "mdbreact";
import React from "react";

const Footer = () => (
  <MDBFooter className="text-left font-small footer-bg darken-2">
    <Container>
      <p className="footer-title mt-3">
        Relevanty © {new Date().getFullYear()}
      </p>
    </Container>
  </MDBFooter>
);

export default Footer;
